import { useEffect, useRef, useCallback, useState } from "react";
import { useDragDropManager } from "react-dnd";
import { GridDefaults } from "legacy/constants/WidgetConstants";
import { getNearestScrollableCanvas } from "legacy/utils/generators";

// NOTE: The actual speed is MAX_DISTANCE_PER_INTERVAL / SCROLL_CHECK_INTERVAL;
// So the current speed is: 1 * GridDefaults.DEFAULT_GRID_ROW_HEIGHT / 100 = 1 * 32 / 100 = 0.32
const SCROLL_CHECK_INTERVAL = 100;
const MAX_DISTANCE_PER_INTERVAL = GridDefaults.DEFAULT_GRID_ROW_HEIGHT * 1;

// A hook that will return if the mouse is at the top or the bottom of the screen within a certain threshold
const useIsMouseTouchingEdge = (
  enabled: boolean,
  threshold: number,
  scrollingContainer: HTMLElement | Window = window,
) => {
  const [isTop, setIsTop] = useState(false);
  const [isBottom, setIsBottom] = useState(false);

  const handleMouseMove = useCallback(
    (event: Event) => {
      const mouse = event as MouseEvent;
      const { clientY } = mouse;
      let top = 0;
      let bottom = 0;
      if (scrollingContainer instanceof Window) {
        top = 0;
        bottom = window.innerHeight;
      } else {
        const rect = scrollingContainer.getBoundingClientRect();
        top = rect.top;
        bottom = rect.bottom;
      }
      setIsTop(clientY < top + threshold);
      setIsBottom(clientY > bottom - threshold);
    },
    [scrollingContainer, threshold],
  );

  useEffect(() => {
    if (!enabled) {
      setIsTop(false);
      setIsBottom(false);
      return;
    }
    window.addEventListener("mousemove", handleMouseMove);
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, [handleMouseMove, scrollingContainer, enabled]);

  return { isTop, isBottom };
};

// A function that will scroll an element to a certain position over a certain duration
function scrollToPosition(element: HTMLElement, to: number, duration: number) {
  const startTime = performance.now();
  let isCancelled = false;

  let animationFrame: number | null;
  function animateScroll(currentTime: number): void {
    if (isCancelled) return;

    const elapsedTime = currentTime - startTime;
    const progress = Math.min(elapsedTime / duration, 1);
    const currentPos = element.scrollTop;
    const newScrollPosition = currentPos + (to - currentPos) * progress;
    element.scrollTop = newScrollPosition;

    if (progress < 1) {
      animationFrame = requestAnimationFrame(animateScroll);
    }
  }
  animationFrame = requestAnimationFrame(animateScroll);

  return {
    cancel: () => {
      isCancelled = true;
      animationFrame && cancelAnimationFrame(animationFrame);
    },
    commit: () => {
      isCancelled = true;
      animationFrame && cancelAnimationFrame(animationFrame);
      element.scrollTop = to;
    },
  };
}

// A hook that will scroll a container to the top or bottom if the mouse is touching the edge
export function useEdgeScrollEffect(el: HTMLElement | null, enabled: boolean) {
  const scrollTarget = useRef<number | undefined>(undefined);

  const container = getNearestScrollableCanvas(el);
  const { isTop, isBottom } = useIsMouseTouchingEdge(enabled, 20);

  const dragDropManager = useDragDropManager();

  useEffect(() => {
    if (!enabled || !container) {
      scrollTarget.current = undefined;
      return;
    }

    if (isTop) {
      scrollTarget.current = 0;
    } else if (isBottom) {
      scrollTarget.current = container.scrollHeight;
    } else {
      scrollTarget.current = undefined;
    }
  }, [isTop, isBottom, enabled, container]);

  useEffect(() => {
    if (!enabled || !container) return;
    let currentScroll: ReturnType<typeof scrollToPosition> | undefined;

    // Setup an interval to scroll the container
    const interval = setInterval(() => {
      if (scrollTarget.current === undefined) return;
      currentScroll?.commit();

      const current = container.scrollTop;
      const goal = scrollTarget.current;
      const closeToGoal = Math.abs(current - goal) < 1;
      if (!closeToGoal) {
        const speed = Math.min(
          Math.abs(goal - current),
          MAX_DISTANCE_PER_INTERVAL,
        );
        const direction = goal > current ? 1 : -1;
        currentScroll = scrollToPosition(
          container,
          current + direction * speed,
          SCROLL_CHECK_INTERVAL,
        );
      }
    }, SCROLL_CHECK_INTERVAL);

    return () => {
      clearInterval(interval);
      currentScroll?.cancel();
    };
  }, [container, dragDropManager, enabled]);
}
