import {
  CUSTOM_THEME_TYPOGRAPHY_KEY,
  NonCustomTypography,
} from "@superblocksteam/shared/src/types/application";
import { createSelector } from "reselect";
import { selectGeneratedTheme } from "legacy/selectors/themeSelectors";
import {
  SB_CUSTOM_TEXT_STYLE,
  USER_SELECTABLE_VARIANTS,
} from "legacy/themes/typographyConstants";
import {
  camelCaseToSentenceCase,
  getCustomTypographyAccessor,
} from "legacy/themes/utils";
import { AllFlags } from "store/slices/featureFlags/models/Flags";
import { DropDownControlOption } from "../DropDownControl";
import { OptionsCustomizerFn } from "./types";
import type { GeneratedTheme } from "legacy/themes";
import type { AppState } from "store/types";

const CUSTOM_OPTION: DropDownControlOption = {
  label: "Custom",
  value: SB_CUSTOM_TEXT_STYLE,
};

const selectProps = (_state: AppState, props: any) => props;
const selectPropertyName = (
  _state: AppState,
  _props: any,
  propertyName: string | undefined,
) => propertyName;
const selectFlags = (
  _state: AppState,
  _props: any,
  _propertyName: string | undefined,
  flags: Partial<AllFlags> | undefined,
) => flags;
const selectAdditionalVariants = (
  _state: AppState,
  _props: any,
  _propertyName: string | undefined,
  _flags: Partial<AllFlags> | undefined,
  additionalVariants: Array<NonCustomTypography> | undefined,
) => additionalVariants;
const selectOptionsCustomizer = (
  _state: AppState,
  _props: any,
  _propertyName: string | undefined,
  _flags: Partial<AllFlags> | undefined,
  _additionalVariants: Array<NonCustomTypography> | undefined,
  optionsCustomizer: OptionsCustomizerFn | undefined,
) => optionsCustomizer;

// This selector can be optimized: props and optionsCustomizer are not always used.
// The config is hard-coded per widget, not by the user
export const selectTypographyVariantsAsDropDownControlOptions = createSelector(
  selectGeneratedTheme,
  selectProps,
  selectPropertyName,
  selectFlags,
  selectAdditionalVariants,
  selectOptionsCustomizer,
  (
    theme,
    props,
    propertyName,
    flags,
    additionalUserSelectableVariants,
    optionsCustomizer,
  ) => {
    const options = USER_SELECTABLE_VARIANTS.concat(
      additionalUserSelectableVariants ?? [],
    ).map((typefaceName) => {
      return {
        label: camelCaseToSentenceCase(typefaceName),
        value: typefaceName,
        subText:
          theme.typographies[
            typefaceName as keyof GeneratedTheme["typographies"]
          ]?.fontSize || undefined,
        subTextPosition: "right",
      };
    }) as DropDownControlOption[];

    if (theme.typographies[CUSTOM_THEME_TYPOGRAPHY_KEY]) {
      Object.entries(theme.typographies[CUSTOM_THEME_TYPOGRAPHY_KEY]).forEach(
        ([customKey, entry]) => {
          if (entry) {
            options.push({
              label: entry.name,
              value: getCustomTypographyAccessor(customKey),
              subText: entry?.styles?.fontSize || undefined,
              subTextPosition: "right",
            });
          }
        },
      );
    }

    const optionsWithCustom = [...options, CUSTOM_OPTION];

    return optionsCustomizer && propertyName
      ? optionsCustomizer({
          propertyName,
          props,
          flags,
          options: optionsWithCustom,
        })
      : optionsWithCustom;
  },
);
