import moment from "moment-timezone";

export const NO_TZ_FORMAT = "YYYY-MM-DDTHH:mm:ss";

/**
 * Parses a date string according to the given format, removes timezone information,
 * and returns the date in NO_TZ format.
 *
 * @param {string} value - The date string to parse.
 * @param {string} format - The format of the input date string.
 * @returns {string} - The formatted date string in "MM/DD/YYYY HH:mm:ss" format.
 */
export function stripTimezoneFromString(
  dateString: string,
  format: string,
): string {
  // If the format includes timezone information (Z), parse without considering it
  if (formatIncludesTimezone(format)) {
    // Temporarily remove timezone data for parsing to avoid moment adjusting the time
    const formatWithoutTZ = format.replace(/Z$/, "");
    const dateStringWithoutTZ = dateString
      .replace(/[-+]\d{2}:\d{2}$/, "")
      .replace(/Z$/, "");

    // Parse the date string without timezone information
    const parsedDate = moment(dateStringWithoutTZ, formatWithoutTZ, true);
    return parsedDate.format(NO_TZ_FORMAT);
  } else {
    // If the date string does not include timezone, parse it directly
    const parsedDate = moment(dateString, format, true);
    return parsedDate.format(NO_TZ_FORMAT);
  }
}
/*
Because the datepicker UI component works with JavaScript dates (which use the system TZ),
we want to "strip" the timezone when passing it to this copmonent.

For example:
If the selected date is 01/01/2001 01:00PM EST and the system time is PST, we want to create a JS Date that has the value
01/01/2001 01:00PM PST and pass that to the blueprint datepicker.

Then, when the user selects a date, we receive a date object with a value of 01/01/2001 02:00PM PST
which we then convert to 01/01/2001 2:00PM EST to store as the selectedDate
*/

export const stripTimezoneFromDate = (
  date: Date,
  dateFormat: string,
  timezone?: string,
): string => {
  try {
    if (timezone) {
      if (formatIncludesTimezone(dateFormat)) {
        const dateStrNoTimezone = moment(date).format(NO_TZ_FORMAT);
        const converted = convertDateStringTimezone(
          dateStrNoTimezone,
          NO_TZ_FORMAT,
          timezone,
          undefined,
        );
        return moment(converted, NO_TZ_FORMAT).tz(timezone).format(dateFormat);
      }
      const dateStrNoTimezone = moment(date).format(NO_TZ_FORMAT);
      return moment(dateStrNoTimezone, NO_TZ_FORMAT).format(dateFormat);
    } else {
      return moment(date).format(dateFormat);
    }
  } catch (e) {
    return moment(date).format(NO_TZ_FORMAT);
  }
};

export const convertDateStringTimezone = (
  dateStr: string,
  dateFormat: string,
  originalTimezone?: string,
  newTimezone?: string,
): string => {
  if (originalTimezone === newTimezone) {
    return dateStr;
  } else {
    const date = originalTimezone
      ? moment.tz(dateStr, [dateFormat, NO_TZ_FORMAT], originalTimezone).utc()
      : moment(dateStr, [dateFormat, NO_TZ_FORMAT]).utc();

    if (!date.isValid()) {
      return dateStr;
    }
    if (newTimezone) {
      return date.tz(newTimezone).format(dateFormat);
    } else {
      return date.local().format(dateFormat);
    }
  }
};

export const formatIncludesTimezone = (dateFormat: string) => {
  return (
    (typeof dateFormat === "string" &&
      dateFormat.toLowerCase().indexOf("z") !== -1) ||
    dateFormat.toLowerCase() === "x"
  );
};

const TIME_MARKERS = [
  "H",
  "h",
  "k",
  "m",
  "s",
  "S",
  "A",
  "a",
  "Z",
  "z",
  "x",
  "X",
];

export const formatIncludesTime = (dateFormat: string) => {
  return TIME_MARKERS.some((marker) => dateFormat.includes(marker));
};
