import { Tooltip } from "antd";
import React, { useCallback, useMemo, useState } from "react";
import { colors } from "styles/colors";
import { styleAsClass } from "styles/styleAsClass";
import { PixelInput } from "./PixelInput";

const firstItemInRowStyle = styleAsClass`
    .ant-input-number {
        padding-left: 18px;
    }
  .ant-input-number, .ant-input-number-group-addon {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: 0;
  }
`;

const secondItemInRowStyle = styleAsClass`
    .ant-input-number {
        padding-left: 18px;
    }
  .ant-input-number {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: 0;
  }
`;

const inputGroupStyle = styleAsClass`
  display: grid;
  grid-template-columns: 100%;
  grid-auto-rows: min-content;
  row-gap: 6px;
  flex-grow: 1;
  .ant-input-number {
    font-size: 12px;
  }
  .ant-input-number-group-wrapper {
    width: 100%;
  }
`;

const inputRowStyle = styleAsClass`
  display: grid;
  grid-template-columns: 50% 1px 50%;
  &:hover .divider {
    background: ${colors.ACCENT_BLUE_500};
  }
  > div {
    position: relative;
  }
  input {
    padding: 0px 6px;
  }
`;

const svgWrapStyle = styleAsClass`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 8px;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    color: ${colors.GREY_700};
  }
`;

const dividerStyle = styleAsClass`
  height: 100%;
  width: 1px;
  background: ${colors.GREY_100};
  transition: background 0.2s;
  &[data-focus="true"] {
    background: ${colors.ACCENT_BLUE_500};
  }
`;

const svgWrapStyleLessPadding = styleAsClass`
  left: 6px;
`;
type Key =
  | "left"
  | "right"
  | "top"
  | "bottom"
  | "topLeft"
  | "topRight"
  | "bottomLeft"
  | "bottomRight";

const getIconColor = ({
  focusedKey,
  key,
  values,
  iconColor,
  type,
}: {
  focusedKey: string | null;
  key: Key;
  values: { [K in Key]?: number | null };
  iconColor: string;
  type?: "padding" | "margin" | "border" | "spacing";
}): string => {
  return focusedKey === key ||
    ((type === "padding" || type === "margin") && Boolean(values[key]))
    ? iconColor
    : colors.GREY_300;
};

export const AdvancedSizeInputs = ({
  LeftIconComponent,
  values,
  tooltipLabel,
  onValueChange,
  onEnter,
  onBlur,
  mode = "side",
  readOnly,
  type,
}: {
  LeftIconComponent: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  values: { [K in Key]?: number | null };

  tooltipLabel: string;
  onValueChange: (value: number | null, key: Key) => void;
  onEnter: () => void;
  onBlur?: () => void;
  mode: "side" | "corner";
  readOnly?: boolean;
  allowNegativeValues?: boolean;
  type?: "padding" | "margin" | "border" | "spacing";
}) => {
  const { keys, labels }: { keys: Array<Key>; labels: Array<string> } =
    useMemo(() => {
      if (mode === "side") {
        return {
          keys: ["left", "top", "right", "bottom"],
          labels: ["Left", "Top", "Right", "Bottom"],
        };
      }
      return {
        keys: ["topLeft", "topRight", "bottomLeft", "bottomRight"],
        labels: ["Top left", "Top right", "Bottom left", "Bottom right"],
      };
    }, [mode]);
  const [focusedKey, setFocusedKey] = useState<string | null>(null);
  const handleFocus = (key: string) => {
    setFocusedKey(key);
  };

  const handleBlur = useCallback(() => {
    setFocusedKey(null);
    onBlur?.();
  }, [onBlur]);

  return (
    <div className={inputGroupStyle} data-type={type}>
      <div className={inputRowStyle}>
        <div>
          <div className={svgWrapStyle}>
            <Tooltip title={`${labels[0]} ${tooltipLabel}`}>
              <LeftIconComponent
                style={{
                  color: getIconColor({
                    focusedKey,
                    key: keys[0],
                    values,
                    iconColor: colors.ACCENT_BLUE_500,
                    type,
                  }),
                }}
              />
            </Tooltip>
          </div>
          <PixelInput
            title={keys[0]}
            value={values[keys[0]]}
            onChange={(value) => onValueChange(value, keys[0])}
            onPressEnter={onEnter}
            className={firstItemInRowStyle}
            onFocus={() => handleFocus(keys[0])}
            onBlur={handleBlur}
            disabled={readOnly}
          />
        </div>
        <div
          className={`${dividerStyle} divider`}
          data-focus={focusedKey === keys[0] || focusedKey === keys[1]}
          data-type={type}
        />
        <div>
          <div className={[svgWrapStyle, svgWrapStyleLessPadding].join(" ")}>
            <Tooltip title={`${labels[1]} ${tooltipLabel}`}>
              <LeftIconComponent
                style={{
                  transform: "rotate(90deg)",
                  color: getIconColor({
                    focusedKey,
                    key: keys[1],
                    values,
                    iconColor: colors.ACCENT_BLUE_500,
                    type,
                  }),
                }}
              />
            </Tooltip>
          </div>
          <PixelInput
            title={keys[1]}
            value={values[keys[1]]}
            onChange={(value) => onValueChange(value, keys[1])}
            onPressEnter={onEnter}
            className={secondItemInRowStyle}
            onFocus={() => handleFocus(keys[1])}
            onBlur={handleBlur}
            disabled={readOnly}
          />
        </div>
      </div>
      <div className={inputRowStyle}>
        <div>
          <div className={svgWrapStyle}>
            <Tooltip title={`${labels[2]} ${tooltipLabel}`}>
              <LeftIconComponent
                style={{
                  transform:
                    mode === "corner" ? "rotate(270deg)" : "rotate(180deg)",
                  color: getIconColor({
                    focusedKey,
                    key: keys[2],
                    values,
                    iconColor: colors.ACCENT_BLUE_500,
                    type,
                  }),
                }}
              />
            </Tooltip>
          </div>
          <PixelInput
            title={keys[2]}
            value={values[keys[2]]}
            onChange={(value) => onValueChange(value, keys[2])}
            onPressEnter={onEnter}
            className={firstItemInRowStyle}
            onFocus={() => handleFocus(keys[2])}
            controls={false}
            onBlur={handleBlur}
            disabled={readOnly}
          />
        </div>
        <div
          className={`${dividerStyle} divider`}
          data-focus={
            (focusedKey === keys[2] && Boolean(values[keys[3]])) ||
            focusedKey === keys[3]
          }
          data-type={type}
        />
        <div>
          <div className={[svgWrapStyle, svgWrapStyleLessPadding].join(" ")}>
            <Tooltip title={`${labels[3]} ${tooltipLabel}`}>
              <LeftIconComponent
                style={{
                  transform:
                    mode === "corner" ? "rotate(180deg)" : "rotate(270deg)",
                  color: getIconColor({
                    focusedKey,
                    key: keys[3],
                    values,
                    iconColor: colors.ACCENT_BLUE_500,
                    type,
                  }),
                }}
              />
            </Tooltip>
          </div>
          <PixelInput
            title={keys[3]}
            value={values[keys[3]]}
            onChange={(value) => onValueChange(value, keys[3])}
            onPressEnter={onEnter}
            className={secondItemInRowStyle}
            onFocus={() => handleFocus(keys[3])}
            controls={false}
            onBlur={handleBlur}
            disabled={readOnly}
          />
        </div>
      </div>
    </div>
  );
};
