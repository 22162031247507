import { css } from "styled-components";
import { WIDGET_PADDING } from "legacy/constants/WidgetConstants";

// raw strings for use with stylus
// NOTE: var(--label-margin) is theme based and is set in themeStyles
export const labelStyleRaw = {
  vertical: `
    margin: var(--label-margin) var(--label-margin) var(--label-margin) 0;
  `,
  horizontal: `
    margin: 8px ${WIDGET_PADDING * 2}px 4px 0;
  `,
};

// styled-components objects
export const labelStyle = {
  vertical: css`
    ${labelStyleRaw.vertical}
  `,
  horizontal: css`
    ${labelStyleRaw.horizontal}
  `,
};
