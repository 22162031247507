import { Dimension, Padding } from "@superblocksteam/shared";
import { omit } from "lodash";
import React, {
  useContext,
  useRef,
  memo,
  useMemo,
  useCallback,
  useEffect,
  useState,
} from "react";
import scrollIntoView from "scroll-into-view-if-needed";
import { useIsKeyDownState } from "hooks/ui/useIsKeyDown";
import { WidgetResize } from "legacy/actions/pageActions";
import { EditorContext } from "legacy/components/editorComponents/EditorContextProvider";
import { GridDefaults, WIDGET_PADDING } from "legacy/constants/WidgetConstants";
import {
  useWidgetSelection,
  useWidgetDragResize,
} from "legacy/hooks/dragResizeHooks";
import { getParentToOpenIfAny } from "legacy/hooks/useClickOpenPropPane";
import { isElementPartiallyVisible } from "legacy/pages/Editor/visibilityUtil";
import {
  selectIsDragging,
  selectIsResizing,
} from "legacy/selectors/dndSelectors";
import { getFlattenedCanvasWidgets } from "legacy/selectors/editorSelectors";
import {
  getSingleSelectedWidgetId,
  getIsMultipleWidgetsSelected,
  getIsWidgetFocused,
  isGridWidgetCellContainer,
} from "legacy/selectors/sagaSelectors";
import AnalyticsUtil from "legacy/utils/AnalyticsUtil";
import { getNearestScrollableCanvas } from "legacy/utils/generators";
import { scrollElementIntoParentCanvasView } from "legacy/utils/helpers";
import VisibilityContainer from "legacy/widgets/base/VisibilityContainer";
import { isDynamicSize } from "legacy/widgets/base/sizing";
import { useAppSelector } from "store/helpers";
import { AppState } from "store/types";
import {
  getComponentDimensions,
  hasRuntimePositionProperties,
} from "utils/size";
import { getMouseCoord } from "../../../../hooks/ui/mouseCoord";
import { getResponsiveCanvasScaleFactor } from "../../../selectors/applicationSelectors";
import { WidgetOperations } from "../../WidgetOperations";
import { DropTargetContext } from "../DropTargetUtils";
import Resizable from "../Resizable";
import {
  UIElementSize,
  computeFinalRowCols,
  computeRowCols,
  getAvailableRectInDropZone,
} from "../ResizableUtils";
import { CantFit } from "../ResizableUtils/getAvailableRectInDropZone";
import {
  LeftHandleWithRectangleStyles,
  RightHandleWithRectangleStyles,
  BottomSwapHandleStyles,
  TopHandleWithRectangleStyles,
  BottomHandleWithRectangleStyles,
} from "../ResizeStyledComponents";
import {
  FitContentVerticalResizeDisabledTooltip,
  allHandles,
  makeHandle,
  getNewDimensions,
} from "./common";
import { useClearSectionSizingContext } from "./useClearSectionSizingContext";
import type { WidgetPropsRuntime } from "../../BaseWidget";
import type { XYCoord } from "react-dnd";

type ResizableComponentProps = WidgetPropsRuntime & {
  ignoreCollision?: boolean;
  hasInvalidProps: boolean;
};

const KEY_DOWN_KEYS = ["Shift"];

const ResizableComponent = memo((props: ResizableComponentProps) => {
  const resizableRef = useRef<HTMLDivElement>(null);
  // Fetch information from the context
  const { updateWidget } = useContext(EditorContext);

  const [isWidgetResizing, setIsWidgetResizing] = useState(false);

  const shiftKeyIsDown = useIsKeyDownState(KEY_DOWN_KEYS);
  const metaKeyIsDown = useIsKeyDownState(["Meta"]);

  const {
    occupiedSpaces: occupiedSpacesBySiblingWidgets,
    persistDropTargetRows,
    updateDropTargetRows,
  } = useContext(DropTargetContext);

  const { selectWidgets, focusWidget } = useWidgetSelection();
  const { setIsResizing, setWidgetResizingDimensions } = useWidgetDragResize();

  const selectedWidget = useAppSelector(getSingleSelectedWidgetId);
  const isWidgetFocused = useAppSelector((state: AppState) =>
    getIsWidgetFocused(state, props.widgetId),
  );
  const widgetIsGridWidgetCellContainer = useAppSelector((state: AppState) =>
    isGridWidgetCellContainer(state, props.widgetId),
  );

  const multipleWidgetsSelected = useAppSelector(getIsMultipleWidgetsSelected);

  const isDragging = useAppSelector(selectIsDragging);
  const isResizing = useAppSelector(selectIsResizing);

  const canvasWidgets = useAppSelector(getFlattenedCanvasWidgets);
  const parentWidgetToSelect = getParentToOpenIfAny(
    props.widgetId,
    canvasWidgets,
  );

  const isWidgetSelected = selectedWidget === props.widgetId;
  const isParentSelected = Boolean(
    parentWidgetToSelect && selectedWidget === parentWidgetToSelect.widgetId,
  );

  // Important: We need this exception for the root grid cell containers to be resizable
  const selectedOrGridCellContainerFocused =
    isWidgetSelected || (isWidgetFocused && widgetIsGridWidgetCellContainer);

  const { componentHeight, componentWidth } = useMemo(() => {
    return getComponentDimensions(props);
  }, [props]);

  // Calculate the dimensions of the widget,
  // The ResizableContainer's size prop is controlled
  // TODO(Layouts): use Dimensions type
  const dimensions: UIElementSize = useMemo(
    () => ({
      width: componentWidth - 2 * WIDGET_PADDING,
      height: componentHeight - 2 * WIDGET_PADDING,
    }),
    [componentWidth, componentHeight],
  );

  const boundingRectGridUnits:
    | { height: number; width: number; padding?: Padding }
    | undefined = useMemo(() => {
    const parentWidget = canvasWidgets[props.parentId];
    if (!parentWidget) return;
    if (!hasRuntimePositionProperties(parentWidget)) return;
    return {
      width: parentWidget.gridColumns as number,
      height: parentWidget.height.value,
    };
  }, [canvasWidgets, props.parentId]);

  const canvasScaleFactor = useAppSelector(getResponsiveCanvasScaleFactor);

  const {
    widgetId,
    widgetName,
    type,
    left,
    top,
    width,
    height,
    parentRowSpace,
    parentColumnSpace,
  } = props;

  // onResize handler
  // Checks if the current resize position has any collisions
  // If yes, set isColliding flag to true.
  // If no, set isColliding flag to false.
  const isSpaceAvailable = useCallback(
    (newDimensions: UIElementSize, position: XYCoord) => {
      const bottom =
        Dimension.toGridUnit(top, parentRowSpace).raw().value +
        position.y / parentRowSpace +
        newDimensions.height / parentRowSpace;

      // Make sure to calculate collision IF we don't update the main container's rows
      let bottomBoundingHeight =
        boundingRectGridUnits?.height ?? Number.MAX_SAFE_INTEGER;
      if (updateDropTargetRows) {
        bottomBoundingHeight = updateDropTargetRows(widgetId, bottom);
        const el = resizableRef.current;
        const scrollParent = getNearestScrollableCanvas(resizableRef.current);
        scrollElementIntoParentCanvasView(
          el,
          scrollParent,
          getMouseCoord(),
          canvasScaleFactor,
        );
      }

      // this is required for list widget so that template have no collision
      if (props.ignoreCollision) return true;

      const delta: UIElementSize = {
        height: newDimensions.height - dimensions.height,
        width: newDimensions.width - dimensions.width,
      };
      const newRowCols = computeRowCols(delta, position, {
        left: Dimension.toGridUnit(left, parentColumnSpace).raw().value,
        top: Dimension.toGridUnit(top, parentRowSpace).raw().value,
        width: Dimension.toGridUnit(width, parentColumnSpace).raw().value,
        height: Dimension.toGridUnit(height, parentRowSpace).raw().value,
        parentColumnSpace,
        parentRowSpace,
      });

      const parentRect = {
        left: 0,
        top: 0,
        bottom: bottomBoundingHeight,
        right: boundingRectGridUnits?.width
          ? boundingRectGridUnits.width
          : GridDefaults.DEFAULT_GRID_COLUMNS,
      };
      const widgetRect = {
        left: newRowCols.left,
        top: newRowCols.top,
        bottom: newRowCols.top + newRowCols.height,
        right: newRowCols.left + newRowCols.width,
      };
      // TODO(Layouts): use height,width in getAvailableRectInDropZone
      const rect = getAvailableRectInDropZone(
        parentRect,
        widgetRect,
        widgetId,
        type,
        occupiedSpacesBySiblingWidgets,
        false,
      );
      // Check if new row cols are occupied by sibling widgets
      return rect !== CantFit;
    },
    [
      boundingRectGridUnits?.height,
      boundingRectGridUnits?.width,
      canvasScaleFactor,
      dimensions.height,
      dimensions.width,
      height,
      left,
      occupiedSpacesBySiblingWidgets,
      parentColumnSpace,
      parentRowSpace,
      props.ignoreCollision,
      top,
      type,
      updateDropTargetRows,
      widgetId,
      width,
    ],
  );

  const clearColumnCanvasExtensionRows = useClearSectionSizingContext();

  const snapGrid = useMemo(
    () => ({
      x: props.parentColumnSpace,
      y: metaKeyIsDown ? 1 : props.parentRowSpace,
    }),
    [props.parentColumnSpace, props.parentRowSpace, metaKeyIsDown],
  );

  // onResizeStop handler
  // when done resizing, check if;
  // 1) There is no collision
  // 2) There is a change in widget size
  // Update widget, if both of the above are true.
  const updateSize = useCallback(
    (newDimensions: UIElementSize, position: XYCoord) => {
      // Get the difference in size of the widget, before and after resizing.
      const delta: UIElementSize = {
        height: newDimensions.height - dimensions.height - 2 * WIDGET_PADDING,
        width: newDimensions.width - dimensions.width - 2 * WIDGET_PADDING,
      };

      // Get the updated Widget rows and columns props
      // False, if there is collision
      // False, if none of the rows and cols have changed.
      const newPosition = computeFinalRowCols(delta, position, {
        left: Dimension.toGridUnit(left, parentColumnSpace).raw().value,
        top: Dimension.toGridUnit(top, parentRowSpace).raw().value,
        width: Dimension.toGridUnit(width, parentColumnSpace).raw().value,
        height: Dimension.toGridUnit(height, parentRowSpace).raw().value,
        parentColumnSpace,
        parentRowSpace,
        roundHeight: !metaKeyIsDown,
      });

      if (newPosition) {
        const finalSizing = getNewDimensions({
          currentWidth: props.width,
          currentHeight: props.height,
          newDimensions: {
            height: newPosition.height * newPosition.parentRowSpace,
            width: newPosition.width * newPosition.parentColumnSpace,
          },
          parentColumnSpace,
          parentRowSpace,
        });

        persistDropTargetRows &&
          persistDropTargetRows(widgetId, newPosition.top + newPosition.height);

        const payload: Omit<WidgetResize, "widgetId"> = {
          position: {
            left: Dimension.gridUnit(newPosition.left),
            top: Dimension.gridUnit(newPosition.top),
          },
          size: {
            height: finalSizing.newHeight,
            width: finalSizing.newWidth,
          },
        };

        updateWidget &&
          updateWidget(WidgetOperations.WIDGET_RESIZE, widgetId, payload);
      }
      // Tell the Canvas that we've stopped resizing
      setIsResizing?.(false);
      setIsWidgetResizing(false);
      if (props.openParentPropertyPane && parentWidgetToSelect) {
        // Only select the parent if we are resizing a hidden containers like the
        // first cell in a grid, since these don't have any properties
        selectWidgets &&
          !isParentSelected &&
          selectWidgets([parentWidgetToSelect.widgetId]);
        focusWidget(parentWidgetToSelect.widgetId);
      } else {
        // Keep the focus on the current resizable otherwise
        selectWidgets && !isWidgetSelected && selectWidgets([widgetId]);
      }

      clearColumnCanvasExtensionRows();

      AnalyticsUtil.logEvent("WIDGET_RESIZE_END", {
        widgetName: widgetName,
        widgetType: type,
        startHeight: dimensions.height,
        startWidth: dimensions.width,
        endHeight: newDimensions.height,
        endWidth: newDimensions.width,
      });
    },
    [
      dimensions,
      left,
      parentColumnSpace,
      top,
      parentRowSpace,
      width,
      height,
      metaKeyIsDown,
      setIsResizing,
      props.openParentPropertyPane,
      props.width,
      props.height,
      parentWidgetToSelect,
      clearColumnCanvasExtensionRows,
      widgetName,
      type,
      persistDropTargetRows,
      widgetId,
      updateWidget,
      selectWidgets,
      isParentSelected,
      focusWidget,
      isWidgetSelected,
    ],
  );

  const handleResizeStart = useCallback(() => {
    if (setIsResizing && !isResizing) {
      setIsResizing(true);
    }
    if (selectWidgets && selectedWidget !== widgetId) {
      selectWidgets([widgetId]);
    }
    setIsWidgetResizing(true);
    AnalyticsUtil.logEvent("WIDGET_RESIZE_START", {
      widgetName: widgetName,
      widgetType: type,
    });
  }, [
    isResizing,
    type,
    widgetId,
    widgetName,
    selectWidgets,
    selectedWidget,
    setIsResizing,
  ]);

  // This is the memoization value, it's primarily used by the Grid component
  const disabledResizeHandles = JSON.stringify(
    (props as any).disabledResizeHandles ?? [],
  );
  const dynamicHeight = isDynamicSize(props.height.mode);

  const handles = useMemo(() => {
    if (dynamicHeight) {
      return {
        left: makeHandle(LeftHandleWithRectangleStyles),
        right: makeHandle(RightHandleWithRectangleStyles),
        bottom: makeHandle(
          BottomSwapHandleStyles as any,
          FitContentVerticalResizeDisabledTooltip,
        ),
        top: makeHandle(
          TopHandleWithRectangleStyles,
          FitContentVerticalResizeDisabledTooltip,
        ),
      };
    }

    return {
      ...omit(allHandles, JSON.parse(disabledResizeHandles)),
      ...(widgetIsGridWidgetCellContainer
        ? {
            bottom: makeHandle(BottomHandleWithRectangleStyles as any),
          }
        : {}),
    };
  }, [dynamicHeight, disabledResizeHandles, widgetIsGridWidgetCellContainer]);

  useEffect(() => {
    if (
      selectedWidget === widgetId &&
      resizableRef.current &&
      !isElementPartiallyVisible(resizableRef.current)
    ) {
      scrollIntoView(resizableRef.current, {
        behavior: "smooth",
        block: "nearest",
        inline: "center",
        scrollMode: "if-needed",
      });
    }
  }, [selectedWidget, widgetId]);

  return (
    <Resizable
      ref={resizableRef}
      handles={handles}
      componentHeight={dimensions.height}
      componentWidth={dimensions.width}
      onStart={handleResizeStart}
      onStop={updateSize}
      onResize={setWidgetResizingDimensions}
      snapGrid={snapGrid}
      enable={
        isWidgetResizing ||
        (!isDragging &&
          selectedOrGridCellContainerFocused &&
          !multipleWidgetsSelected &&
          !props.resizeDisabled &&
          !shiftKeyIsDown)
      }
      isSelected={isWidgetSelected}
      isSpaceAvailable={isSpaceAvailable}
      hasInvalidProps={props.hasInvalidProps}
      widget={props}
      insetBottomHandleOverride={widgetIsGridWidgetCellContainer}
    >
      <VisibilityContainer isVisible={!!props.isVisible} widgetId={widgetId}>
        {props.children}
      </VisibilityContainer>
    </Resizable>
  );
});

ResizableComponent.displayName = "ResizableComponent";
export default ResizableComponent;
