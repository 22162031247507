import { DimensionModes } from "@superblocksteam/shared";
import React from "react";
import { createSelector } from "reselect";
import { ReactComponent as HorizontalAlignmentLeft } from "assets/icons/common/horizontal-alignment-left.svg";
import { ReactComponent as HorizontalAlignmentMiddle } from "assets/icons/common/horizontal-alignment-middle.svg";
import { ReactComponent as HorizontalAlignmentRight } from "assets/icons/common/horizontal-alignment-right.svg";
import { ReactComponent as HorizontalDistributionBottom } from "assets/icons/common/horizontal-distribution-bottom.svg";
import { ReactComponent as HorizontalDistributionMiddle } from "assets/icons/common/horizontal-distribution-middle.svg";
import { ReactComponent as HorizontalDistributionSpaceAround } from "assets/icons/common/horizontal-distribution-space-around.svg";
import { ReactComponent as HorizontalDistributionSpaceBetween } from "assets/icons/common/horizontal-distribution-space-between.svg";
import { ReactComponent as HorizontalDistributionStretch } from "assets/icons/common/horizontal-distribution-stretch.svg";
import { ReactComponent as HorizontalDistributionTop } from "assets/icons/common/horizontal-distribution-top.svg";
import { ReactComponent as LayoutFixedIcon } from "assets/icons/common/layout-fixed.svg";
import { ReactComponent as LayoutHorizontalArrowsIcon } from "assets/icons/common/layout-horizontal-arrows.svg";
import { ReactComponent as LayoutVerticalArrowsIcon } from "assets/icons/common/layout-vertical-arrows.svg";
import { ReactComponent as VerticalAlignmentLeft } from "assets/icons/common/vertical-alignment-left.svg";
import { ReactComponent as VerticalAlignmentMiddle } from "assets/icons/common/vertical-alignment-middle.svg";
import { ReactComponent as VerticalAlignmentRight } from "assets/icons/common/vertical-alignment-right.svg";
import { ReactComponent as VerticalAlignmentStretch } from "assets/icons/common/vertical-alignment-stretch.svg";
import { ReactComponent as VerticalDistributionBottom } from "assets/icons/common/vertical-distribution-bottom.svg";
import { ReactComponent as VerticalDistributionMiddle } from "assets/icons/common/vertical-distribution-middle.svg";
import { ReactComponent as VerticalDistributionSpaceAround } from "assets/icons/common/vertical-distribution-space-around.svg";
import { ReactComponent as VerticalDistributionSpaceBetween } from "assets/icons/common/vertical-distribution-space-between.svg";
import { ReactComponent as VerticalDistributionTop } from "assets/icons/common/vertical-distribution-top.svg";
import { type RadioButtonGroupOption } from "components/ui/RadioButtonGroup";
import { SpacingIconPrefix } from "legacy/components/utils/IconUtil";
import {
  PropsPanelCategory,
  type Hidden,
  type PropertyPaneControlConfig,
} from "legacy/constants/PropertyControlConstants";
import {
  CanvasLayout,
  StackCanvasLayout,
  CanvasDistribution,
  CanvasAlignment,
} from "legacy/constants/WidgetConstants";

import { getCanvasWidgets } from "legacy/selectors/entitiesSelector";
import { Flag, Flags } from "store/slices/featureFlags";
import { AppState } from "store/types";
import { type WidgetProps } from "./BaseWidget";
import { isStackLayout } from "./StackLayout/utils";

const ALIGNMENT_ICONS: Record<
  StackCanvasLayout,
  {
    [CanvasAlignment.LEFT]: React.ReactNode;
    [CanvasAlignment.CENTER]: React.ReactNode;
    [CanvasAlignment.RIGHT]: React.ReactNode;
    [CanvasAlignment.STRETCH]: React.ReactNode;
    [CanvasAlignment.SPACE_BETWEEN]: React.ReactNode;
    [CanvasAlignment.SPACE_AROUND]: React.ReactNode;
  }
> = {
  [CanvasLayout.VSTACK]: {
    [CanvasAlignment.LEFT]: <VerticalAlignmentLeft />,
    [CanvasAlignment.CENTER]: <VerticalAlignmentMiddle />,
    [CanvasAlignment.RIGHT]: <VerticalAlignmentRight />,
    [CanvasAlignment.STRETCH]: <VerticalAlignmentStretch />,
    [CanvasAlignment.SPACE_BETWEEN]: <></>, // not used for VSTACK
    [CanvasAlignment.SPACE_AROUND]: <></>, // not used for VSTACK
  },
  [CanvasLayout.HSTACK]: {
    [CanvasAlignment.LEFT]: <HorizontalAlignmentLeft />,
    [CanvasAlignment.CENTER]: <HorizontalAlignmentMiddle />,
    [CanvasAlignment.RIGHT]: <HorizontalAlignmentRight />,
    [CanvasAlignment.STRETCH]: <></>, // not used for HSTACK
    [CanvasAlignment.SPACE_BETWEEN]: <HorizontalDistributionSpaceBetween />,
    [CanvasAlignment.SPACE_AROUND]: <HorizontalDistributionSpaceAround />,
  },
};

const DISTRIBUTION_ICONS: Record<
  StackCanvasLayout,
  {
    [CanvasDistribution.TOP]: React.ReactNode;
    [CanvasDistribution.CENTER]: React.ReactNode;
    [CanvasDistribution.BOTTOM]: React.ReactNode;
    [CanvasDistribution.STRETCH]: React.ReactNode;
    [CanvasAlignment.SPACE_BETWEEN]: React.ReactNode;
    [CanvasAlignment.SPACE_AROUND]: React.ReactNode;
  }
> = {
  [CanvasLayout.VSTACK]: {
    [CanvasDistribution.TOP]: <VerticalDistributionTop />,
    [CanvasDistribution.CENTER]: <VerticalDistributionMiddle />,
    [CanvasDistribution.BOTTOM]: <VerticalDistributionBottom />,
    [CanvasDistribution.STRETCH]: <></>, // not used for VSTACK
    [CanvasAlignment.SPACE_BETWEEN]: <VerticalDistributionSpaceBetween />,
    [CanvasAlignment.SPACE_AROUND]: <VerticalDistributionSpaceAround />,
  },
  [CanvasLayout.HSTACK]: {
    [CanvasDistribution.TOP]: <HorizontalDistributionTop />,
    [CanvasDistribution.CENTER]: <HorizontalDistributionMiddle />,
    [CanvasDistribution.BOTTOM]: <HorizontalDistributionBottom />,
    [CanvasDistribution.STRETCH]: <HorizontalDistributionStretch />,
    [CanvasAlignment.SPACE_BETWEEN]: <></>, // not used for HSTACK
    [CanvasAlignment.SPACE_AROUND]: <></>, // not used for HSTACK
  },
};

const getTargetWidgetId = (props: any) => props.children?.[0] ?? "";

const getSpacingProps = (isLayoutOnChild: boolean, isMinSpace?: boolean) => {
  return {
    propertyName: "spacing",
    propertyCategory: PropsPanelCategory.Layout,
    labelIconPrefix: SpacingIconPrefix,
    key: isMinSpace ? "minSpacing" : "spacing",
    label: isMinSpace ? "Min Spacing" : "Spacing",
    controlType: "SPACING_CONTROL",
    helpText:
      "Space between components in container. If the alignment is space-between or space-around, this is the minimum spacing between items",
    isJSConvertible: false,
    isBindProperty: true,
    isTriggerProperty: false,
    getTargetWidgetId: isLayoutOnChild ? getTargetWidgetId : undefined,
    hidden: ((props, propertyPath, flags) => {
      const isSpaceAlignment =
        props.alignment === CanvasAlignment.SPACE_AROUND ||
        props.alignment === CanvasAlignment.SPACE_BETWEEN;
      if (
        (!isMinSpace && isSpaceAlignment) ||
        (isMinSpace && !isSpaceAlignment)
      ) {
        return true;
      }

      return !isStackLayout(props?.layout);
    }) as Hidden,
  };
};

export const layoutProperties = ({
  isLayoutOnChild = false,
}: {
  isLayoutOnChild?: boolean;
}): PropertyPaneControlConfig[] => [
  {
    propertyName: "layout",
    propertyCategory: PropsPanelCategory.Layout,
    label: "Layout",
    controlType: "RADIO_BUTTON_GROUP",
    isBindProperty: false,
    isTriggerProperty: false,
    hideLabelsForUnselected: true,
    defaultValue: CanvasLayout.FIXED,
    helpText: "Layout system for components in container",
    optionsSelector: createSelector(
      getCanvasWidgets,
      (state: AppState, widget: WidgetProps, flags?: Flags) => widget,
      (state: AppState, widget: WidgetProps, flags?: Flags) => flags,
      (widgets, widget, flags) => {
        const parent = widgets[widget.parentId];
        const fixedDisabled = parent?.width?.mode === "fitContent";
        const fixedDisabledReason =
          "Grid layout is not supported with fit content width";
        const isV2Panel = flags?.[Flag.ENABLE_PROPS_PANEL_V2];

        const options: RadioButtonGroupOption[] = [
          {
            label: isV2Panel
              ? ""
              : fixedDisabled
              ? fixedDisabledReason
              : "Grid",
            value: CanvasLayout.FIXED,
            icon: <LayoutFixedIcon />,
            disabled: fixedDisabled,
            showTooltip: Boolean(isV2Panel),
            tooltip: isV2Panel ? "Grid" : undefined,
          },
          {
            label: isV2Panel ? "" : "Vertical",
            value: CanvasLayout.VSTACK,
            icon: <LayoutVerticalArrowsIcon />,
            showTooltip: Boolean(isV2Panel),
            tooltip: isV2Panel ? "Vertical" : undefined,
          },
          {
            label: isV2Panel ? "" : "Horizontal",
            value: CanvasLayout.HSTACK,
            icon: <LayoutHorizontalArrowsIcon />,
            showTooltip: Boolean(isV2Panel),
            tooltip: isV2Panel ? "Horizontal" : undefined,
          },
        ];

        return options;
      },
    ),
    getTargetWidgetId: isLayoutOnChild ? getTargetWidgetId : undefined,
  },
  {
    propertyName: "distribution",
    propertyCategory: PropsPanelCategory.Layout,
    label: "Vertical align",
    controlType: "RADIO_BUTTON_GROUP",
    isBindProperty: false,
    helpText: "Vertical distribution of components in container",
    isTriggerProperty: false,
    hideLabelsForUnselected: true,
    defaultValue: CanvasDistribution.TOP,
    optionsSelector: createSelector(
      getCanvasWidgets,
      (state: AppState, widget: WidgetProps) => widget,
      (state: AppState, widget: WidgetProps, flags?: Flags) => flags,
      (canvasWidgets, widget, flags) => {
        const layout = widget.layout || CanvasLayout.VSTACK;
        if (layout === CanvasLayout.FIXED) {
          return [];
        }
        const isV2Panel = flags?.[Flag.ENABLE_PROPS_PANEL_V2];

        const options: RadioButtonGroupOption[] = [
          {
            label: isV2Panel ? "" : "Top",
            value: CanvasDistribution.TOP,
            icon: DISTRIBUTION_ICONS[layout][CanvasDistribution.TOP],
            showTooltip: Boolean(isV2Panel),
            tooltip: isV2Panel ? "Top" : undefined,
          },
          {
            label: isV2Panel ? "" : "Center",
            value: CanvasDistribution.CENTER,
            icon: DISTRIBUTION_ICONS[layout][CanvasDistribution.CENTER],
            showTooltip: Boolean(isV2Panel),
            tooltip: isV2Panel ? "Center" : undefined,
          },
          {
            label: isV2Panel ? "" : "Bottom",
            value: CanvasDistribution.BOTTOM,
            icon: DISTRIBUTION_ICONS[layout][CanvasDistribution.BOTTOM],
            showTooltip: Boolean(isV2Panel),
            tooltip: isV2Panel ? "Bottom" : undefined,
          },
        ];
        if (widget?.layout === CanvasLayout.HSTACK) {
          const parent = canvasWidgets[widget.parentId];
          const disabled = parent?.height?.mode === "fitContent";
          const disabledReason =
            "Stretched vertical alignment is not supported when height is set to fit content";
          options.push({
            label: isV2Panel ? "" : disabled ? disabledReason : "Stretch",
            value: CanvasDistribution.STRETCH,
            icon: DISTRIBUTION_ICONS[layout][CanvasDistribution.STRETCH],
            disabled,
            showTooltip: Boolean(isV2Panel),
            tooltip: isV2Panel ? "Stretch" : undefined,
          });
        }
        if (widget?.layout === CanvasLayout.VSTACK) {
          options.push(
            {
              label: isV2Panel ? "" : "Between",
              value: CanvasDistribution.SPACE_BETWEEN,
              icon: DISTRIBUTION_ICONS[layout][
                CanvasDistribution.SPACE_BETWEEN
              ],
              showTooltip: Boolean(isV2Panel),
              tooltip: "Space between",
            },
            {
              label: isV2Panel ? "" : "Around",
              value: CanvasDistribution.SPACE_AROUND,
              icon: DISTRIBUTION_ICONS[layout][CanvasDistribution.SPACE_AROUND],
              showTooltip: Boolean(isV2Panel),
              tooltip: "Space around",
            },
          );
        }

        return options;
      },
    ),
    getTargetWidgetId: isLayoutOnChild ? getTargetWidgetId : undefined,
    hidden: (props, propertyPath, flags) => {
      return !isStackLayout(props?.layout);
    },
  },
  {
    propertyName: "alignment",
    propertyCategory: PropsPanelCategory.Layout,
    label: "Horizontal align",
    // Uses custom control because Stretch modifies the children
    controlType: "STACK_ALIGN",
    isBindProperty: false,
    isTriggerProperty: false,
    hideLabelsForUnselected: true,
    helpText: "Horizontal alignment of components in container",
    defaultValue: CanvasAlignment.STRETCH,
    optionsSelector: createSelector(
      getCanvasWidgets,
      (state: AppState, widget: WidgetProps) => widget,
      (state: AppState, widget: WidgetProps, flags?: Flags) => flags,
      (canvasWidgets, widget, flags) => {
        const layout = widget.layout || CanvasLayout.VSTACK;
        if (layout === CanvasLayout.FIXED) {
          return [];
        }
        const isV2Panel = flags?.[Flag.ENABLE_PROPS_PANEL_V2];
        const options: RadioButtonGroupOption[] = [
          {
            label: isV2Panel ? "" : "Left",
            value: CanvasAlignment.LEFT,
            icon: ALIGNMENT_ICONS[layout][CanvasAlignment.LEFT],
            showTooltip: Boolean(isV2Panel),
            tooltip: isV2Panel ? "Left" : undefined,
          },
          {
            label: isV2Panel ? "" : "Center",
            value: CanvasAlignment.CENTER,
            icon: ALIGNMENT_ICONS[layout][CanvasAlignment.CENTER],
            showTooltip: Boolean(isV2Panel),
            tooltip: isV2Panel ? "Center" : undefined,
          },
          {
            label: isV2Panel ? "" : "Right",
            value: CanvasAlignment.RIGHT,
            icon: ALIGNMENT_ICONS[layout][CanvasAlignment.RIGHT],
            showTooltip: Boolean(isV2Panel),
            tooltip: isV2Panel ? "Right" : undefined,
          },
        ];
        if (widget?.layout === CanvasLayout.VSTACK) {
          const parent = canvasWidgets[widget.parentId];
          const disabled = parent?.width?.mode === "fitContent";
          const disabledReason =
            "Stretched horizontal alignment is not supported when width is set to fit content";
          options.push({
            label: isV2Panel ? "" : disabled ? disabledReason : "Stretch",
            value: CanvasAlignment.STRETCH,
            icon: ALIGNMENT_ICONS[layout][CanvasAlignment.STRETCH],
            disabled,
            showTooltip: Boolean(isV2Panel),
            tooltip: isV2Panel ? "Stretch" : undefined,
          });
        }
        if (widget?.layout === CanvasLayout.HSTACK) {
          options.push(
            {
              label: isV2Panel ? "" : "Between",
              showTooltip: Boolean(isV2Panel),
              tooltip: "Space between",
              value: CanvasAlignment.SPACE_BETWEEN,
              icon: ALIGNMENT_ICONS[layout][CanvasAlignment.SPACE_BETWEEN],
            },
            {
              label: isV2Panel ? "" : "Around",
              showTooltip: Boolean(isV2Panel),
              tooltip: "Space around",
              value: CanvasAlignment.SPACE_AROUND,
              icon: ALIGNMENT_ICONS[layout][CanvasAlignment.SPACE_AROUND],
            },
          );
        }
        return options;
      },
    ),
    getTargetWidgetId: isLayoutOnChild ? getTargetWidgetId : undefined,
    hidden: (props, propertyPath, flags) => {
      return !isStackLayout(props?.layout);
    },
  },
  getSpacingProps(isLayoutOnChild, false),
  getSpacingProps(isLayoutOnChild, true),
];

export const heightModeSupportsMinMax = (heightMode: DimensionModes) => {
  return heightMode === "fillParent" || heightMode === "fitContent";
};

export const widthModeSupportsMinMax = (widthMode: DimensionModes) => {
  return (
    widthMode === "fillParent" ||
    widthMode === "fitContent" ||
    widthMode === "gridUnit"
  );
};
