import {
  PropsPanelCategory,
  type PropertyPaneControlConfig,
} from "legacy/constants/PropertyControlConstants";

export const iconProperty = (
  overrides?: Partial<PropertyPaneControlConfig>,
): PropertyPaneControlConfig => {
  return {
    propertyName: "icon",
    label: "Icon",
    helpText: "Select an icon",
    controlType: "ICON_SELECTOR",
    isBindProperty: true,
    isTriggerProperty: false,
    isJSConvertible: true,
    canExpandEditor: true,
    customJSControl: "CODE_EDITOR_ICON_SELECTOR",
    isRemovable: true,
    visibility: "SHOW_NAME",
    defaultValue: "{{ icons.info }}",
    propertyCategory: PropsPanelCategory.Appearance,
    ...overrides,
  };
};

export const iconPositionProperty = <T extends { icon?: any }>(
  overrides?: Partial<PropertyPaneControlConfig>,
): PropertyPaneControlConfig => {
  return {
    propertyName: "iconPosition",
    helpText: "The alignment of the icon",
    label: "Icon position",
    controlType: "RADIO_BUTTON_GROUP",
    options: [
      {
        icon: "ICON_LEFT_ALIGN",
        value: "LEFT",
        label: "Left",
      },
      {
        icon: "ICON_RIGHT_ALIGN",
        value: "RIGHT",
        label: "Right",
      },
    ],
    isBindProperty: false,
    isTriggerProperty: false,
    defaultValue: "LEFT",
    hidden: (props: T & Record<string, any>) => {
      if (typeof overrides?.propertyName === "string") {
        return !props[overrides.propertyName];
      }
      return !props.icon;
    },
    propertyCategory: PropsPanelCategory.Appearance,
    ...overrides,
  };
};

export const minimumFractionDigitsProperty = (
  overrides?: Partial<PropertyPaneControlConfig>,
): PropertyPaneControlConfig => {
  return {
    propertyName: "minimumFractionDigits",
    label: "Min decimals",
    helpText: "The minimum number of digits after the decimal separator",
    controlType: "INPUT_TEXT",
    isBindProperty: true,
    isTriggerProperty: false,
    placeholderText: "# between 0-20",
    propertyCategory: PropsPanelCategory.Appearance,
    isRemovable: true,
    defaultValue: "1",
    visibility: "SHOW_NAME",
    ...overrides,
  };
};

export const maximumFractionDigitsProperty = (
  overrides?: Partial<PropertyPaneControlConfig>,
): PropertyPaneControlConfig => {
  return {
    propertyName: "maximumFractionDigits",
    label: "Max decimals",
    helpText: "The maximum number of digits after the decimal separator",
    controlType: "INPUT_TEXT",
    isBindProperty: true,
    isTriggerProperty: false,
    placeholderText: "# between 0-20",
    isRemovable: true,
    defaultValue: "2",
    visibility: "SHOW_NAME",
    propertyCategory: PropsPanelCategory.Appearance,
    ...overrides,
  };
};
