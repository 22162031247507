import { colors } from "styles/colors";
import { styleAsClass } from "styles/styleAsClass";

export const unselectedButtonStyle = styleAsClass/*css*/ `
  &&& {
    height: 32px;
    width: 32px;
    padding: 5px 7px;
    display: flex;
    align-items: center;
    justify-content: center;


    border-color: ${colors.GREY_100};
    svg {
        color: ${colors.GREY_300};
    }
    
    &:hover, &:focus {
      border-color: ${colors.GREY_300};
      svg {
        color: ${colors.GREY_300};
      }
    }
  }
`;
export const selectedButtonStyle = styleAsClass/*css*/ `
  &&& {
    height: 32px;
    width: 32px;
    padding: 5px 7px;
    display: flex;
    align-items: center;
    justify-content: center;

    border-color: ${colors.ACCENT_BLUE_500_25};
    background: ${colors.ACCENT_BLUE_500_25};
    svg {
      color: ${colors.ACCENT_BLUE_500};
    }

    &:hover, &:focus {
        border-color: ${colors.ACCENT_BLUE_500_50};
        background: ${colors.ACCENT_BLUE_500_50};
        svg {
          color: ${colors.ACCENT_BLUE_500};
        }
    }
  }
`;
