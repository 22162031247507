import { throttle } from "lodash";
import { useEffect, useRef } from "react";
import { DragLayerMonitor, useDragDropManager } from "react-dnd";
import { getNearestScrollableCanvas } from "legacy/utils/generators";

/**
 * This function is used to reconcile the scroll difference between the drag layer and the container
 * The DND library does not provide a way to get the scroll difference between the drag layer and the container
 * It seems to get out of sync when the drag layer is moved around
 * so we have to calculate it manually
 *
 * When do we reset the scrollYMod?
 * 1. When the drag layer is not being dragged
 * 2. When the drag layer is being dragged but the offset has changed
 * 3. When the height of the container has shrunk
 */
export function useHandleScrollDiff(
  el: HTMLElement | null,
  enabled: any,
  onScrollDiff?: (diff: number, monitor: DragLayerMonitor<unknown>) => void,
) {
  const scrollYMod = useRef(0);
  const container = getNearestScrollableCanvas(el);

  const monitor = useDragDropManager().getMonitor();
  const isEnabled = useRef(enabled);
  isEnabled.current = enabled;
  useEffect(() => {
    if (!container) return;

    let lastOffset = monitor.getSourceClientOffset()?.y;
    let lastHeight = container.scrollHeight;

    let scrollAtLastOffset = container.scrollTop;
    const handleScroll = () => {
      if (isEnabled.current && monitor.isDragging()) {
        const offset = monitor.getSourceClientOffset()?.y;
        const offsetChanged = offset !== lastOffset;
        const heightShrunk = container.scrollHeight < lastHeight;
        if (offsetChanged || heightShrunk) {
          lastOffset = offset;
          scrollAtLastOffset = container.scrollTop;
          lastHeight = container.scrollHeight;
        }

        const scrollDiff = container.scrollTop - scrollAtLastOffset;
        scrollYMod.current = scrollDiff;
        // we need to update the collected state to trigger a re-render
        onScrollDiff?.(scrollDiff, monitor);
      } else {
        scrollYMod.current = 0;
      }
    };

    const throttledScrollHandler = throttle(handleScroll, 100);
    container.addEventListener("scroll", throttledScrollHandler);
    return () => {
      scrollYMod.current = 0;
      container.removeEventListener("scroll", throttledScrollHandler);
    };
  }, [container, monitor, onScrollDiff]);
  return scrollYMod;
}
